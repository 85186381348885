import React from 'react';
import TABGenerator from './TABGenerator';
import './App.css'

function App() {
  return (
    <div className='App'>
      <TABGenerator />
    </div>
  );
}

export default App;
